<template>
  <div>
    <v-hover v-slot="{ hover }">
      <v-list-item>
        <v-list-item-content>
          {{ ingredient.description }}
        </v-list-item-content>
        <v-list-item-action>
          <v-btn
            :loading="loading"
            @click="deleteConfirmDialog = true"
            :class="{ 'visibility-hidden': !hover }"
            icon
          >
            <v-icon color="red lighten-3">{{ icons.delete }}</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
    </v-hover>
    <v-divider></v-divider>

    <v-snackbar v-model="snackbar.show" right :color="snackbar.color">
      {{ snackbar.message }}
      <template v-slot:action="{ attrs }">
        <v-btn text @click="snackbar.show = false" v-bind="attrs">OK</v-btn>
      </template>
    </v-snackbar>

    <ConfirmModal
      v-model="deleteConfirmDialog"
      title="Delete meal ingredient"
      message="Are you sure you want to delete this ingredient?"
      @cancel="deleteConfirmDialog = false"
      @confirm="deleteIngredient"
    ></ConfirmModal>
  </div>
</template>

<script>
import { mdiTrashCanOutline } from '@mdi/js'
import ConfirmModal from '@/components/modals/ConfirmModal'

export default {
  props: {
    value: {
      type: Object
    },
    meal: {
      type: Object
    }
  },

  components: {
    ConfirmModal
  },

  data() {
    return {
      loading: false,
      ingredient: this.value,
      deleteConfirmDialog: false,
      icons: {
        delete: mdiTrashCanOutline
      },
      snackbar: {
        show: false,
        message: null,
        color: ''
      }
    }
  },

  methods: {
    deleteIngredient() {
      this.loading = true

      this.$api
        .delete(`meals/${this.meal.id}/ingredients/${this.ingredient.id}`)
        .then(res => {
          this.$store.commit('meal/setMealDetails', res.data.data)
          this.showSnackbar('Meal Ingredient successfully deleted!', 'success')
        })
        .catch(() => {
          this.showSnackbar('Something went wrong!', 'error')
        })
        .finally(() => (this.loading = false))
    },

    showSnackbar(message, color) {
      this.snackbar.message = message
      this.snackbar.show = true
      this.snackbar.color = color
    }
  }
}
</script>

<style scoped>
.visibility-hidden {
  visibility: hidden;
}
</style>
