<template>
  <v-card v-if="meal" class="mx-auto my-12" max-width="374">
    <template slot="progress">
      <v-progress-linear
        color="deep-purple"
        height="10"
        indeterminate
      ></v-progress-linear>
    </template>

    <v-img height="250" :src="thumbSrc(meal.image)"></v-img>

    <v-card-title>{{ meal.name }}</v-card-title>

    <v-list-item dense>
      <v-list-item-icon>
        <v-icon>{{ icons.prep }}</v-icon>
      </v-list-item-icon>
      <v-list-item-subtitle>{{ meal.prep_time }} mins</v-list-item-subtitle>
    </v-list-item>

    <v-list-item dense>
      <v-list-item-icon>
        <v-icon>{{ icons.cook }}</v-icon>
      </v-list-item-icon>
      <v-list-item-subtitle>{{ meal.cook_time }} mins</v-list-item-subtitle>
    </v-list-item>

    <v-list-item dense>
      <v-list-item-icon>
        <v-icon>{{ icons.serve }}</v-icon>
      </v-list-item-icon>
      <v-list-item-subtitle
        >Serves {{ meal.serving_quantity }}</v-list-item-subtitle
      >
    </v-list-item>

    <v-divider class="ma-4"></v-divider>

    <div class="mx-4" v-if="restrictions.length > 0">
      <v-row justify="start">
        <v-col
          cols="3"
          class="text-center"
          v-for="(restriction, key) in restrictions"
          :key="key"
        >
          <v-avatar color="grey" light rounded>
            <span class="text-h5">{{ restriction.initial }}</span>
          </v-avatar>
          <span class="text-caption two-lines">{{ restriction.name }}</span>
        </v-col>
      </v-row>
    </div>

    <div class="text-caption ma-4" v-else>No Restrictions</div>

    <v-divider class="ma-4"></v-divider>

    <v-card-title>Nutrition per serving</v-card-title>

    <v-row class="mx-4 text-center">
      <v-col>
        <div class="font-weight-normal">Kcal</div>
        <div class="text-caption">{{ meal.calories }}</div>
      </v-col>
      <v-col>
        <div class="font-weight-normal">Fats</div>
        <div class="text-caption">{{ meal.fats }}g</div>
      </v-col>
      <v-col>
        <div class="font-weight-normal">Carbs</div>
        <div class="text-caption">{{ meal.carbs }}g</div>
      </v-col>
      <v-col>
        <div class="font-weight-normal">Protein</div>
        <div class="text-caption">{{ meal.protein }}g</div>
      </v-col>
    </v-row>

    <v-divider class="ma-4"></v-divider>

    <v-card-title>Ingredients</v-card-title>

    <template v-if="meal.ingredients.length > 0">
      <ul class="mx-4">
        <li v-for="(ingredient, key) in meal.ingredients" :key="key">
          {{ ingredient.description }}
        </li>
      </ul>
    </template>

    <div class="px-4 text-caption" v-else>No meal ingredients</div>

    <v-divider class="ma-4"></v-divider>

    <v-card-title>Method</v-card-title>

    <template v-if="meal.directions.length > 0">
      <v-timeline align-top dense>
        <v-timeline-item
          v-for="(direction, key) in meal.directions"
          :key="key"
          fill-dot
        >
          <template v-slot:icon>
            <span class="white--text">{{ key + 1 }}</span>
          </template>
          <div class="mr-4">
            {{ direction.description }}
          </div>
        </v-timeline-item>
      </v-timeline>
    </template>

    <div class="px-4 pb-5 text-caption" v-else>No meal directions</div>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'
import { mdiBowl, mdiPot, mdiAccount } from '@mdi/js'

export default {
  data: () => ({
    icons: {
      prep: mdiBowl,
      cook: mdiPot,
      serve: mdiAccount
    }
  }),

  computed: {
    ...mapState({
      meal: state => state.meal.mealDetails
    }),

    restrictions() {
      return [
        ...this.meal.allergy_restriction,
        ...this.meal.dietary_restriction
      ]
    }
  },

  methods: {
    thumbSrc(image) {
      return image ? image.url : require('@/assets/images/default-image.jpeg')
    }
  }
}
</script>

<style scoped>
.two-lines {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.v-card__title {
  word-break: break-word;
}
</style>
