<template>
  <div class="pa-8">
    <v-form>
      <v-row>
        <v-col>
          <div class="d-flex justify-space-between align-center mb-4">
            <h2 class="mr-auto">Meal Directions</h2>
          </div>
        </v-col>
      </v-row>
      <v-form @submit.prevent="addMealDirection" ref="form">
        <v-row>
          <v-col>
            <v-textarea
              outlined
              auto-grow
              counter="255"
              label="Description *"
              hide-details="auto"
              persistent-placeholder
              v-model="form.description"
              :error-messages="form.$getError('description')"
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="text-right">
            <v-btn
              type="submit"
              color="primary"
              :loading="form.$busy"
              depressed
            >
              Add Direction
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
      <v-divider class="mt-16"></v-divider>
      <template v-if="meal && meal.directions.length > 0">
        <draggable
          :list="meal.directions"
          :sort="true"
          @change="updateMealState"
        >
          <div v-for="direction in meal.directions" :key="direction.id">
            <MealDirectionListItem
              :value="direction"
              :meal="meal"
            ></MealDirectionListItem>
          </div>
        </draggable>
      </template>
      <div class="pa-4 text-caption" v-else>No meal ingredients</div>
    </v-form>
    <v-snackbar v-model="snackbar.show" right :color="snackbar.color">
      {{ snackbar.message }}
      <template v-slot:action="{ attrs }">
        <v-btn text @click="snackbar.show = false" v-bind="attrs">OK</v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import draggable from 'vuedraggable'
import Form from '@/utils/form'
import MealDirectionListItem from './MealDirectionListItem.vue'

export default {
  components: {
    draggable,
    MealDirectionListItem
  },

  data() {
    return {
      loading: false,
      form: new Form({
        description: null
      }),
      snackbar: {
        show: false,
        message: null,
        color: ''
      }
    }
  },

  computed: {
    ...mapState({
      meal: state => state.meal.mealDetails
    })
  },

  methods: {
    addMealDirection() {
      this.form.$busy = true
      this.form.$clearErrors()
      this.$api
        .post(`meals/${this.meal.id}/directions`, this.form.$data())
        .then(res => {
          this.$store.commit('meal/setMealDetails', res.data.data)
          this.$refs.form.reset()
          this.showSnackbar('Meal Direction successfully added!', 'success')
        })
        .catch(err => {
          if (err.response.status === 422) {
            this.form.$setErrors(err.response.data.errors)
          }
        })
        .finally(() => (this.form.$busy = false))
    },

    updateMealState() {
      this.$store.commit('meal/setMealDetails', this.meal)
      // this.$api.post(`meals/${this.meal.id}/ingredients`)
    },

    showSnackbar(message, color) {
      this.snackbar.message = message
      this.snackbar.show = true
      this.snackbar.color = color
    }
  }
}
</script>
