<template>
  <div>
    <v-app-bar flat color="transparent" class="main-appbar">
      <app-bar-nav-icon />
      <v-skeleton-loader
        type="chip"
        transition="fade-transition"
        v-if="loading"
      ></v-skeleton-loader>

      <v-toolbar-title v-else class="headline font-weight-bold"
        >Meals ({{ listMeta.total }})</v-toolbar-title
      >

      <v-spacer></v-spacer>

      <v-btn color="primary" class="mb-2" @click="addMealDialog = true">
        <v-icon left> {{ icons.add }} </v-icon>Add Meal
      </v-btn>
    </v-app-bar>

    <div class="mt-12 mb-6">
      <v-row>
        <v-col cols="12" md="3">
          <v-text-field
            outlined
            clearable
            hide-details
            label="Search"
            @input="doFilter"
            placeholder="Search by name"
            v-model="search"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="2">
          <v-select
            outlined
            hide-details
            @change="doFilter"
            :items="meal_status_items"
            label="Filter by Status"
            v-model="filters.status"
          ></v-select>
        </v-col>
      </v-row>
    </div>

    <v-data-table
      :items="meals"
      :loading="loading"
      :headers="headers"
      class="elevation-1"
      :options.sync="options"
      :server-items-length="listMeta.total"
      :footer-props="{ 'items-per-page-options': [10, 20, 50, 100] }"
    >
      <template v-slot:top="{ pagination, options, updateOptions }">
        <v-data-footer
          :pagination="pagination"
          :options="options"
          @update:options="updateOptions"
          items-per-page-text="$vuetify.dataTable.itemsPerPageText"
          :items-per-page-options="[10, 20, 50, 100]"
        />
      </template>
      <template v-slot:[`item.thumbnail`]="{ item }">
        <v-avatar tile>
          <img :src="thumbSrc(item.image)" />
        </v-avatar>
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <v-chip class="text-capitalize" :color="getColor(item.status)" dark>
          {{ item.status }}
        </v-chip>
      </template>
      <template v-slot:[`item.created_at`]="{ item }">
        {{ item.addedDate }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn
          small
          block
          depressed
          @click="
            $router.push({ name: 'meals.details', params: { id: item.id } })
          "
        >
          Edit
        </v-btn>
      </template>
    </v-data-table>

    <AddMealDialog v-model="addMealDialog"></AddMealDialog>
  </div>
</template>

<script>
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'
import ControlsMixin from '@/utils/mixins/Controls'
import { mdiPlus } from '@mdi/js'
import { mapState, mapActions, mapMutations } from 'vuex'
import AddMealDialog from './components/AddMealDialog.vue'
import debounce from 'lodash/debounce'

export default {
  name: 'MealsPage',

  mixins: [ControlsMixin],

  components: {
    AppBarNavIcon,
    AddMealDialog
  },

  data() {
    return {
      options: {},
      loading: false,
      search: null,
      addMealDialog: false,
      meal_status_items: [
        { text: 'All', value: null },
        { text: 'Active', value: 'active' },
        { text: 'Disabled', value: 'disabled' }
      ],
      icons: {
        add: mdiPlus
      },
      filters: {
        status: null
      },
      headers: [
        {
          text: 'Thumbnail',
          sortable: false,
          value: 'thumbnail',
          width: '150px'
        },
        {
          text: 'Name',
          sortable: true,
          value: 'name'
        },
        {
          text: 'Status',
          sortable: false,
          value: 'status',
          width: '250px'
        },
        {
          text: 'Date Created',
          sortable: true,
          value: 'created_at',
          width: '250px'
        },
        {
          text: 'Actions',
          align: 'center',
          sortable: false,
          value: 'actions',
          width: '150px'
        }
      ]
    }
  },

  computed: {
    ...mapState({
      meals: state => state.meal.list,
      listMeta: state => state.meal.listMeta
    })
  },

  methods: {
    ...mapActions({
      getMeals: 'meal/getMeals'
    }),

    ...mapMutations({
      clearMeals: 'meal/clearMealList'
    }),

    doFilter: debounce(function() {
      this.getDataFromApi()
    }, 600),

    async getDataFromApi() {
      this.loading = true
      this.clearMeals()
      await this.getMeals({
        search: this.search,
        filters: this.filters,
        ...this.options
      })
      this.loading = false
    },

    thumbSrc(image) {
      return image ? image.url : require('@/assets/images/default-image.jpeg')
    },

    getColor(status) {
      if (status === 'disabled') return 'red'

      return 'green'
    }
  },

  watch: {
    options: {
      handler() {
        this.getDataFromApi()
      },
      deep: true
    }
  }
}
</script>
