<template>
  <div class="pa-8">
    <v-form v-if="form" @submit.prevent="submitForm" ref="form">
      <v-row>
        <v-col>
          <div class="d-flex justify-space-between align-center mb-4">
            <h2 class="mr-auto">Meal Details</h2>
            <v-btn
              type="submit"
              color="primary"
              width="150px"
              :loading="form.$busy"
              depressed
            >
              Save
            </v-btn>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <image-upload
            :image="meal.image"
            @change="imageChange"
            @removed="imageRemoved"
            :imageLoading="imageLoading"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-text-field
            outlined
            required
            label="Name *"
            counter="255"
            hide-details="auto"
            persistent-placeholder
            :rules="rules.name"
            v-model="form.name"
            :error-messages="form.$getError('name')"
            @input="updateMealState"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4">
          <v-text-field
            outlined
            required
            persistent-hint
            type="number"
            hint="in minutes"
            label="Preparation Time *"
            hide-details="auto"
            persistent-placeholder
            v-model="form.prep_time"
            :error-messages="form.$getError('prep_time')"
            @input="updateMealState"
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-text-field
            outlined
            required
            persistent-hint
            type="number"
            hint="in minutes"
            label="Cooking Time *"
            hide-details="auto"
            persistent-placeholder
            v-model="form.cook_time"
            :error-messages="form.$getError('cook_time')"
            @input="updateMealState"
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-text-field
            outlined
            required
            min="1"
            max="999"
            type="number"
            label="Serving Quantity *"
            hide-details="auto"
            persistent-placeholder
            v-model="form.serving_quantity"
            :error-messages="form.$getError('serving_quantity')"
            @input="updateMealState"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <v-select
            outlined
            label="Status *"
            hide-details="auto"
            :items="status_items"
            persistent-placeholder
            v-model="form.status"
            :error-messages="form.$getError('status')"
            @input="updateMealState"
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-autocomplete
            :items="meal_type"
            :loading="mealTypesLoading"
            item-text="label"
            item-value="id"
            label="Type"
            multiple
            chips
            outlined
            required
            class="custom-autocomplete"
            hide-details="auto"
            persistent-placeholder
            v-model="form.type"
            return-object
            :error-messages="form.$getError('type')"
            @input="updateMealState"
          >
            <template v-slot:selection="data">
              <v-chip
                :key="JSON.stringify(data.item)"
                v-bind="data.attrs"
                :input-value="data.selected"
                :disabled="data.disabled"
                @click:close="data.parent.selectItem(data.item)"
                close
              >
                {{ data.item.label }}
              </v-chip>
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-autocomplete
            chips
            multiple
            outlined
            required
            return-object
            persistent-placeholder
            hide-details="auto"
            :items="meal_restrictions.allergy"
            item-value="id"
            item-text="name"
            label="Allergy Restrictions"
            class="custom-autocomplete"
            v-model="form.allergy_restriction"
            :error-messages="form.$getError('allergy_restriction')"
            @input="updateMealState"
          >
            <template v-slot:selection="data">
              <v-chip
                :key="JSON.stringify(data.item)"
                v-bind="data.attrs"
                :input-value="data.selected"
                :disabled="data.disabled"
                @click:close="data.parent.selectItem(data.item)"
                close
              >
                {{ data.item.name }}
              </v-chip>
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-autocomplete
            chips
            multiple
            outlined
            required
            return-object
            persistent-placeholder
            hide-details="auto"
            :items="meal_restrictions.dietary"
            item-value="id"
            item-text="name"
            label="Dietary Restrictions"
            class="custom-autocomplete"
            v-model="form.dietary_restriction"
            :error-messages="form.$getError('dietary_restriction')"
            @input="updateMealState"
          >
            <template v-slot:selection="data">
              <v-chip
                :key="JSON.stringify(data.item)"
                v-bind="data.attrs"
                :input-value="data.selected"
                :disabled="data.disabled"
                @click:close="data.parent.selectItem(data.item)"
                close
              >
                {{ data.item.name }}
              </v-chip>
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>
      <div class="text-field-label my-5">Nutrition per serving</div>
      <v-row>
        <v-col cols="12" md="6" lg="3">
          <v-text-field
            outlined
            required
            type="number"
            label="Kcal"
            hide-details="auto"
            persistent-placeholder
            v-model="form.calories"
            :error-messages="form.$getError('calories')"
            @input="updateMealState"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6" lg="3">
          <v-text-field
            outlined
            required
            type="number"
            label="Fats (g)"
            hide-details="auto"
            persistent-placeholder
            v-model="form.fats"
            :error-messages="form.$getError('fats')"
            @input="updateMealState"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6" lg="3">
          <v-text-field
            outlined
            required
            type="number"
            label="Carbs (g)"
            hide-details="auto"
            persistent-placeholder
            v-model="form.carbs"
            :error-messages="form.$getError('carbs')"
            @input="updateMealState"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6" lg="3">
          <v-text-field
            outlined
            required
            type="number"
            label="Protein (g)"
            hide-details="auto"
            persistent-placeholder
            v-model="form.protein"
            :error-messages="form.$getError('protein')"
            @input="updateMealState"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-textarea
            v-model="form.disclaimer"
            outlined
            required
            label="Disclaimer"
            hide-details="auto"
            :error-messages="form.$getError('disclaimer')"
          >
          </v-textarea>
        </v-col>
      </v-row>
    </v-form>

    <v-snackbar v-model="snackbar.show" right :color="snackbar.color">
      {{ snackbar.message }}
      <template v-slot:action="{ attrs }">
        <v-btn text @click="snackbar.show = false" v-bind="attrs">OK</v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import ImageUpload from '@/components/ImageUpload'
import Api from '@/services/api'
import Form from '@/utils/form'

export default {
  name: 'MealDetails',

  components: {
    ImageUpload
  },

  created() {
    this.getMealRestriction()
    this.getMealTypes()
    this.getMeal()
  },

  data() {
    return {
      form: null,
      loading: false,
      imageLoading: false,
      mealTypesLoading: true,
      mealRestrictionsLoading: true,
      meal_restrictions: [],
      meal_type: [],
      status_items: [
        { text: 'Active', value: 'active' },
        { text: 'Disabled', value: 'disabled' }
      ],
      snackbar: {
        show: false,
        message: null,
        color: ''
      },
      rules: {
        name: [v => (v && v.length <= 255) || 'Max 255 characters']
      }
    }
  },

  computed: {
    ...mapState({
      meal: state => state.meal.mealDetails
    })
  },

  methods: {
    ...mapActions({
      getMealDetails: 'meal/getMealDetails',
      updateMeal: 'meal/updateMeal',
      changeImage: 'meal/changeImage',
      removeImage: 'meal/removeImage'
    }),

    async submitForm() {
      this.form.$busy = true
      this.form.$clearErrors()
      this.updateMeal(this.form.$data())
        .then(() => {
          this.showSnackbar('Meal successfully updated!', 'success')
        })
        .catch(err => {
          this.form.$busy = false
          if (err.response.status === 422) {
            this.form.$setErrors(err.response.data.errors)
          }
        })
        .finally(() => (this.form.$busy = false))
    },

    async imageChange(image) {
      this.imageLoading = true
      let data = new FormData()
      data.append('meal_id', this.meal.id)
      data.append('image', image)
      await this.changeImage(data)
      this.imageLoading = false
      this.showSnackbar('Meal image successfully updated!', 'success')
    },

    async imageRemoved() {
      this.meal.image = null
      this.removeImage(this.$route.params.id)
    },

    async getMeal() {
      this.loading = true
      await this.getMealDetails(this.$route.params.id)
      this.loading = false
    },

    async getMealRestriction() {
      Api.get(`meals/restrictions?limit=999999`)
        .then(res => {
          res.data.data.forEach(item => {
            if (!this.meal_restrictions[item['type']]) {
              this.meal_restrictions[item['type']] = []
            }
            this.meal_restrictions[item['type']].push(item)
          })
        })
        .finally(() => (this.mealRestrictionsLoading = false))
    },

    async getMealTypes() {
      Api.get(`meals/types`)
        .then(res => {
          this.meal_type = res.data.data
        })
        .finally(() => (this.mealTypesLoading = false))
    },

    showSnackbar(message, color) {
      this.snackbar.message = message
      this.snackbar.show = true
      this.snackbar.color = color
    },

    updateMealState() {
      this.$store.commit('meal/setMealDetails', this.form.$data())
    }
  },

  watch: {
    '$store.state.meal': {
      handler() {
        this.form = new Form(this.meal)
      },
      deep: true
    }
  }
}
</script>

<style>
.custom-autocomplete .v-select__selections {
  min-height: 0 !important;
}
</style>
