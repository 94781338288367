<template>
  <div class="py-8">
    <v-app-bar flat color="transparent">
      <app-bar-nav-icon />
      <v-toolbar-title class="breadcrumbs d-flex align-center">
        <v-icon class="text-light-blue">{{ icons.arrowLeft }}</v-icon>
        <router-link class="text-decoration--none" :to="{ name: 'meals' }"
          >Meals</router-link
        >
      </v-toolbar-title>

      <div class="flex-grow-1"></div>

      <v-btn @click="deleteConfirmDialog = true" color="error" text dark>
        <v-icon class="mr-3">
          {{ icons.delete }}
        </v-icon>
        Delete Meal
      </v-btn>

      <template v-slot:extension>
        <v-tabs v-model="mealTabs" align-with-title>
          <v-tab>Details</v-tab>
          <v-tab>Ingredients</v-tab>
          <v-tab>Directions</v-tab>
        </v-tabs>
      </template>
    </v-app-bar>

    <v-divider></v-divider>

    <v-row>
      <v-col cols="6">
        <v-tabs-items v-model="mealTabs">
          <v-tab-item :transition="false"><MealDetailsTab /></v-tab-item>
          <v-tab-item :transition="false"><MealIngredientsTab /></v-tab-item>
          <v-tab-item :transition="false"><MealDirectionsTab /></v-tab-item>
        </v-tabs-items>
      </v-col>
      <v-col cols="6"><PreviewMeal /></v-col>
    </v-row>

    <ConfirmModal
      v-model="deleteConfirmDialog"
      title="Delete meal"
      message="Are you sure you want to delete this meal?"
      @cancel="deleteConfirmDialog = false"
      @confirm="deleteMeal"
    ></ConfirmModal>
  </div>
</template>

<script>
import ConfirmModal from '@/components/modals/ConfirmModal'
import { mdiChevronLeft, mdiTrashCanOutline } from '@mdi/js'
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'
import MealDetailsTab from './components/MealDetailsTab.vue'
import MealIngredientsTab from './components/MealIngredientsTab.vue'
import MealDirectionsTab from './components/MealDirectionsTab.vue'
import PreviewMeal from './components/PreviewMeal.vue'
import { mapActions } from 'vuex'

export default {
  name: 'MealDetails',

  components: {
    AppBarNavIcon,
    ConfirmModal,
    PreviewMeal,
    MealDetailsTab,
    MealIngredientsTab,
    MealDirectionsTab
  },

  data() {
    return {
      mealTabs: null,
      deleteLoading: false,
      deleteConfirmDialog: false,
      icons: {
        arrowLeft: mdiChevronLeft,
        delete: mdiTrashCanOutline
      }
    }
  },

  methods: {
    ...mapActions({
      deleteMealAction: 'meal/deleteMeal'
    }),

    async deleteMeal() {
      this.deleteLoading = true
      await this.deleteMealAction(this.$route.params.id)
      this.deleteLoading = false
      this.$router.replace('/meals')
    }
  }
}
</script>
